"use client";
import { useEffect, useState, useRef } from "react";
import styles from "./style.module.scss";
import { motion, useMotionValue, useSpring } from "framer-motion";

export default function Cursor({ stickyElement, heroBannerRef }) {
  const [isHovered, setIsHovered] = useState(false);
  const [isHeroBannerHovered, setIsHeroBannerHovered] = useState(false);
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false); // Track mobile/tablet

  const cursorSize = isHovered ? 52 : 36;

  const mouse = {
    x: useMotionValue(0),
    y: useMotionValue(-40),
  };

  // Smooth out the mouse values
  const smoothOptions = { damping: 60, stiffness: 1000, mass: 1 };
  const smoothMouse = {
    x: useSpring(mouse.x, smoothOptions),
    y: useSpring(mouse.y, smoothOptions),
  };

  const manageMouseMove = (e) => {
    if (!isMobileOrTablet) {
      const { clientX, clientY } = e;
      if (stickyElement.current) {
        const { left, top, height, width } =
          stickyElement.current.getBoundingClientRect();

        // Center position of the stickyElement
        const center = { x: left + width / 2, y: top + height / 2 - 4 };

        if (isHovered) {
          // Distance between the mouse pointer and the center of the custom cursor
          const distance = { x: clientX - center.x, y: clientY - center.y };

          // Move mouse to center of stickyElement + slightly move it towards the mouse pointer
          mouse.x.set(center.x - cursorSize / 2 + distance.x * 0.1);
          mouse.y.set(center.y - cursorSize / 2 + distance.y * 0.1);
        } else {
          // Move custom cursor to center of stickyElement
          mouse.x.set(clientX - cursorSize / 2);
          mouse.y.set(clientY - cursorSize / 2);
        }
      } else {
        mouse.x.set(clientX - cursorSize / 2);
        mouse.y.set(clientY - cursorSize / 2);
      }
    }
  };

  const manageMouseOver = () => {
    setIsHovered(true);
  };

  const manageMouseLeave = () => {
    setIsHovered(false);
  };

  const manageHeroBannerMouseOver = () => {
    setIsHeroBannerHovered(true);
  };

  const manageHeroBannerMouseLeave = () => {
    setIsHeroBannerHovered(false);
  };

  const previousStickyElement = useRef(null);
  const previousHeroBanner = useRef(null);

  useEffect(() => {
    // Check if it's a mobile or tablet device
    const checkIfMobileOrTablet = () => {
      const isMobile = window.innerWidth <= 768;
      const isTablet = window.innerWidth > 768 && window.innerWidth <= 1024; // Add iPad and tablet width range here
      setIsMobileOrTablet(isMobile || isTablet); // Mobile or tablet
    };

    checkIfMobileOrTablet(); // Check on initial load

    window.addEventListener("resize", checkIfMobileOrTablet); // Update on window resize

    const stickyElementCurrent = stickyElement.current;
    const heroBannerCurrent = heroBannerRef.current;

    if (previousStickyElement.current) {
      previousStickyElement.current.removeEventListener(
        "mouseenter",
        manageMouseOver
      );
      previousStickyElement.current.removeEventListener(
        "mouseleave",
        manageMouseLeave
      );
    }

    if (previousHeroBanner.current) {
      previousHeroBanner.current.removeEventListener(
        "mouseenter",
        manageHeroBannerMouseOver
      );
      previousHeroBanner.current.removeEventListener(
        "mouseleave",
        manageHeroBannerMouseLeave
      );
    }

    if (stickyElementCurrent) {
      stickyElementCurrent.addEventListener("mouseenter", manageMouseOver);
      stickyElementCurrent.addEventListener("mouseleave", manageMouseLeave);
    }

    if (heroBannerCurrent) {
      heroBannerCurrent.addEventListener(
        "mouseenter",
        manageHeroBannerMouseOver
      );
      heroBannerCurrent.addEventListener(
        "mouseleave",
        manageHeroBannerMouseLeave
      );
    }

    previousStickyElement.current = stickyElementCurrent;
    previousHeroBanner.current = heroBannerCurrent;

    window.addEventListener("mousemove", manageMouseMove);

    return () => {
      if (stickyElementCurrent) {
        stickyElementCurrent.removeEventListener("mouseenter", manageMouseOver);
        stickyElementCurrent.removeEventListener(
          "mouseleave",
          manageMouseLeave
        );
      }
      if (heroBannerCurrent) {
        heroBannerCurrent.removeEventListener(
          "mouseenter",
          manageHeroBannerMouseOver
        );
        heroBannerCurrent.removeEventListener(
          "mouseleave",
          manageHeroBannerMouseLeave
        );
      }
      window.removeEventListener("mousemove", manageMouseMove);
      window.removeEventListener("resize", checkIfMobileOrTablet);
    };
  }, [stickyElement, heroBannerRef, isHovered, isMobileOrTablet]);

  return (
    <div className={isMobileOrTablet ? "hidden" : "hidden md:block"}>
      <div className={styles.cursorContainer}>
        <motion.div
          style={{
            left: smoothMouse.x,
            top: smoothMouse.y,
            borderColor: "white",
          }}
          animate={{
            width: cursorSize,
            height: cursorSize,
          }}
          className={styles.cursor}></motion.div>
      </div>
    </div>
  );
}
