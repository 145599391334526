import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import LogoIcon from "../components/icons/LogoIcon";
import { Helmet } from "react-helmet";

export default function Contacts() {
  return (
    <div className="overflow-x-hidden">
      <Helmet>
        <title>Contact Us | Marc's Artisanal Pizzeria Miami Beach</title>
        <meta
          name="description"
          content="Visit us at 966 Normandy Drive, Miami Beach, or contact us at (786) 558-5784. Open Wed–Thu: 4pm–11pm. Authentic Italian pizza awaits!"
        />
        <link
          rel="canonical"
          href="https://marcsartisanpizzeria.com/contacts"
        />
      </Helmet>
      <NavBar showImmediately={true} />
      <div className="md:container md:mx-auto mt-[92px] md:mb-8 mx-4 ">
        <div className="flex flex-col gap-2 py-6 border-t-2 border-b-2 md:flex-row md:justify-between border-legno md:items-center">
          <div className="flex flex-col gap-2">
            <h1
              className="mx-4 text-5xl font-bold md:text-6xl font-awesome md:mx-0"
              data-aos="fade-in">
              CONTACTS
            </h1>
            <h2
              className="mx-4 text-xl italic font-awesome-italic md:mx-0 md:text-2xl md:text-porpora "
              data-aos="fade-in"
              data-aos-delay="300">
              Connect with Us
            </h2>
          </div>
        </div>
      </div>
      <div className="md:grid md:grid-cols-2 md:mb-16 md:container md:mx-auto">
        <div className="flex justify-center mx-4 md:order-2 md:mx-0 md:my-16">
          <div
            className="w-full h-full border-0"
            data-aos="fade-in"
            data-aos-delay="200">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6231.809644019087!2d-80.12950099999999!3d25.855652!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9b2e50495279d%3A0x61826460155dfc83!2s966%20Normandy%20Dr%2C%20Miami%20Beach%2C%20FL%2033141%2C%20USA!5e0!3m2!1sen!2sth!4v1736885129204&maptype=roadmap&z=16"
              title="Google Maps"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              style={{
                width: "100%",
                height: "400px", // Default height for mobile
              }}></iframe>
          </div>
        </div>
        <div className="flex flex-col gap-6 mx-8 my-9 md:mx-0">
          <div className="flex flex-col gap-1.5" data-aos="fade-right">
            <h2 className="text-2xl font-bold leading-8 font-awesome text-legno">
              Marc's Artisanal Pizzeria
            </h2>
            <p className="text-xl leading-6 font-awesome font-extralight ">
              966 Normandy Drive, Miami Beach, FL 33141
            </p>
          </div>
          <div
            className="flex flex-col gap-1.5 font-awesome font-extralight text-xl"
            data-aos="fade-right"
            data-aos-delay="200">
            <h3 className="text-2xl font-bold font-awesome">
              Hours of Operation:
            </h3>
            <span>Monday and Tuesday: Closed</span>
            <span>Wednesday – Friday: 4:00 PM – 11:00 PM</span>
            <span>Saturday – Sunday: 12:00 PM – 11:00 PM</span>
          </div>

          <div
            className="flex flex-col gap-1.5 font-awesome font-extralight text-base"
            data-aos="fade-right"
            data-aos-delay="600">
            <h3 className="text-2xl font-bold font-awesome">
              General Inquiries:
            </h3>
            <span className="text-xl">
              <a href="mailto:info@marcsartisanalpizzeria.com">
                info@marcsartisanalpizzeria.com
              </a>
            </span>

            <span className="text-xl">
              <a href="tel:7865585784">(786) 558-5784</a>
            </span>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
