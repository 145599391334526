import { Link } from "react-router-dom";

const OrderNowButton = ({ color }) => {
  let buttonClasses =
    "text-white hover:text-legno duration-300 ease-in-out hover:bg-white border-2 px-6 py-1 rounded-full text-lg font-neuething-medium leading-[22px] flex justify-center items-center";

  if (color === "black") {
    buttonClasses =
      "text-black hover:text-white hover:bg-legno border-2 px-6 py-2 rounded-full text-lg font-neuething-medium border-legno leading-[22px] flex justify-center items-center";
  }

  return (
    <a
      href="https://marcsartisanalpizzeria.toast.site"
      target="_blank"
      rel="noreferrer"
      className={buttonClasses}
      style={{ pointerEvents: "auto", zIndex: 9999 }} // Added pointer-events and z-index
    >
      <h2 className="py-1.5 md:py-2">ORDER NOW</h2>
    </a>
  );
};

export default OrderNowButton;
