import React, { useState, useEffect } from "react";
import Newsletter from "./Newsletter";
import FacebookIcon from "./icons/FacebookIcon";
import InstagramIcon from "./icons/InstagramIcon";
import LogoIcon from "./icons/LogoIcon";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";

const Footer = () => {
  const menuItems = [
    { text: "MENU", to: "/menu" },
    { text: "OUR STORY", to: "/about" },
    { text: "ARTICLES", to: "/articles" },
    { text: "CONTACTS", to: "/contacts" },
    { text: "FAQ", to: "/faqs" },
  ];
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <>
      <div className="md:mx-auto md:container  ">
        <div className="md:border-t-2 md:grid md:grid-cols-12 gap-8 md:border-legno ">
          {/* <div className="md:col-span-5 md:order-1" data-aos="fade-up">
            <Newsletter />
          </div> */}
          <div
            className="md:col-span-5 w-full mt-10 md:order-2  md:grid md:grid-cols-12 h-min md:my-10 "
            data-aos="fade-up">
            <div className="py-6 mx-4 text-4xl font-light text-center border-t-2 font-awesome border-legno md:border-0 md:order-2  md:text-end md:py-0  md:pt-2  md:mx-0 md:col-span-5">
              <a
                target="_blank"
                href="https://marcsartisanalpizzeria.toast.site"
                className="hover:underline decoration-2 underline-offset-4">
                Order online
              </a>
            </div>
            <div className="flex flex-row justify-center py-6 mx-4 border-t-2 gap-9 border-legno md:border-l-2 md:border-r-2 md:border-0   md:h-min md:col-span-7 md:gap-8">
              <a
                href="https://www.facebook.com/marcspizzeria"
                className="hover:scale-110 md:mt-1">
                <FacebookIcon />
              </a>
              <a
                href="https://www.instagram.com/marcspizzeria"
                className="hover:scale-110 md:mt-1">
                <InstagramIcon />
              </a>
            </div>
          </div>

          <div
            className="flex flex-col gap-4 py-6 pb-16 mx-4 text-3xl font-bold text-center border-t-2  md:justify-between border-legno md:border-0 md:col-span-7 md:order-1 md:col-start-1 md:text-start md:gap-3 font-awesome items-center md:items-start md:flex-row md:my-10 h-min md:mx-0"
            data-aos="fade-up">
            {menuItems.map((item, index) => (
              <Link key={index} to={item.to} className=" md:h-0">
                <div
                  className="flex items-center relative"
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}>
                  <h3 className="hover:underline decoration-2 underline-offset-4">
                    {item.text}
                  </h3>
                  {/* <motion.h3
                    initial={{ y: 0 }} // Set initial position at 0
                    animate={{ y: hoveredIndex === index ? -5 : 0 }} // Move right on hover
                    exit={{ x: 0 }} // Reset position on exit
                    transition={{ duration: 0.3 }}
                    className="">
                    {item.text}
                  </motion.h3> */}
                  {/* <AnimatePresence>
                    {hoveredIndex === index && (
                      <motion.span
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }} // Animate only opacity
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        className="absolute  font-awesome-italic italic hidden md:flex">
                        go
                      </motion.span>
                    )}
                  </AnimatePresence> */}
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className=" bg-porpora ">
        <div className="container md:flex-row md:items-start md:justify-between  md:pb-4  flex flex-col md:gap-8 items-center justify-center py-16 text-center">
          <a href="/" className="">
            <LogoIcon color={"#F68A1F"} width={"176"} />
          </a>
          <div className="flex flex-col gap-1 pb-8 mt-8 md:mt-0 text-base text-white border-b md:border-0 border-giallo md:text-start font-neuething-regular">
            <span>Monday and Tuesday: Closed</span>
            <span>Wednesday – Friday: 4:00 PM – 11:00 PM</span>
            <span>Saturday – Sunday: 12:00 PM – 11:00 PM</span>
          </div>
          <div className="flex flex-col gap-1 pb-8 mt-8 md:mt-0 text-base text-white border-b md:border-0 border-giallo md:text-start font-neuething-regular">
            <span>966 Normandy Drive, Miami Beach, FL 33141</span>
            <span>info@marcsartisanalpizzeria.com</span>
            <span>(786) 558-5784</span>
          </div>
          <div className="flex flex-col mt-8 text-base text-white font-neuething-regular md:text-end md:mt-0">
            <span>© 2025 Marc's Artisanal Pizzeria</span>
            <Link to="/legal">
              <span className="font-neuething-bold">
                Privacy Policy & Terms of Service
              </span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
