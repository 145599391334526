import LogoNoTextIcon from "./icons/LogoNoTextIcon";
import AboutHeroImage from "../images/AboutHeroImage.jpg";
import { Link } from "react-router-dom";
import { useRef } from "react";
import Cursor from "./stickyCursor/Cursor";

const HeroBannerAbout = ({ stickyElement }) => {
  const bannerRef = useRef(null);
  return (
    <>
      <div
        className="relative  bg-cover bg-no-repeat bg-center h-[90vh] md:h-screen cursor-none"
        style={{ backgroundImage: `url(${AboutHeroImage})` }}>
        <Cursor stickyElement={stickyElement} heroBannerRef={bannerRef} />
        <div className="flex md:z-10 flex-col justify-center md:justify-start items-center md:items-start md:mt-16 w-full absolute h-full">
          <div
            className="md:container  mx-auto  border-b-2 border-white md:border-0"
            data-aos="fade-in">
            <Link to="/">
              <LogoNoTextIcon color={"white"} width={"236"} />
            </Link>
          </div>
          <div className="md:absolute mt-4 md:mt-0 inset-0 flex justify-center items-center z-10">
            <h2
              className="text-white text-center text-4xl md:text-6xl lg:text-9xl leading-9 font-awesome font-bold"
              data-aos="fade-in"
              data-aos-delay="200">
              OUR STORY
            </h2>
          </div>
        </div>
        <div
          className="h-full md:z-0 md:container flex items-end justify-center md:justify-between md:mx-auto md:mb-16"
          data-aos="fade-up"
          data-aos-delay="500">
          <div className="flex flex-col items-center">
            <h2 className="mx-auto mb-8 text-white md:mx-0 text-3xl md:text-4xl md:mb-12 font-awesome-italic italic">
              From Passion to Plate
            </h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroBannerAbout;
